import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  // 'target',
  'code', 'name', 
  'area', 'pref', 
];
//行ヘッダー列
export const rowHeaderColKeys = [
  'subInputFlg', 
  'rowHeader', 
  'rowHeaderName', 
  'rowHeaderMise', 
];
//ヘッダー列
export const bodyColKeys = [
	'newCenterCd',
	'forecastEndYmd',
	'prevYearExcludedFlg',
];
//ヘッダー列（発注不可）
export const colKeysUnOrderableHidden = [
	'newCenterCd',
	'forecastEndYmd',
	'prevYearExcludedFlg',
];

export const headersCol = [
  {
    no : "No.",
    target : "一括",
    code: "得意先/倉庫", 
    name: "得意先/倉庫", 
  
    area: "得意先/倉庫", pref: "得意先/倉庫", 

    subInputFlg: "得意先支店", 
    rowHeader: "得意先支店", 
    rowHeaderName: "得意先支店", 
    rowHeaderMise: "得意先支店", 

    newCenterCd: "移管先",
    forecastEndYmd: "移管元",
    prevYearExcludedFlg: "移管元",
  },
  {
    no : "",
    target : "",
    code: "<br/>コード", 
    name: "<br/>名称", 
    area: "<br/>地域", pref: "<br/>都道府県", 

    subInputFlg: "店舗別", 
    rowHeader : "CD", 
    rowHeaderName : "名称", 
    rowHeaderMise: "店番号", 
    
    newCenterCd: "移管先センターCD",
    forecastEndYmd: "予測計算終了日",
    prevYearExcludedFlg: "前年実績から除外",
  },
];

export const selectableCol =
{
  no : false,
  code: false, 
  name: false, 

  area: true, pref: true, 

  subInputFlg: false, 
  rowHeader : false, 
  rowHeaderName : true, 
  rowHeaderMise: true, 

  newCenterCd: false,
  forecastEndYmd: false,
  prevYearExcludedFlg: false,
}

export const headerColClasses = [
  {
    no : "header_noBorder_no",
    target : "header_noBorder_bulk",
    code: "header", 
    name: "header", 
    area: "header", pref: "header", 

    subInputFlg: "header",
    rowHeader : "header",
    rowHeaderName : "header", 
    rowHeaderMise: "header", 
  
    newCenterCd: "header",
    forecastEndYmd: "header",
    prevYearExcludedFlg: "header",
  },
  {
    no : "header_no",
    target : "header_bulk",
    code: "header", 
    name: "header", 
    area: "header", pref: "header", 

    subInputFlg: "header",
    rowHeader : "header", 
    rowHeaderName : "header", 
    rowHeaderMise: "header", 

    newCenterCd: "header",
    forecastEndYmd: "header",
    prevYearExcludedFlg: "header",
  },
];
export const headerRowClasses = {
  const:'rowHeader'
};

export const colWidths =
{
  no : 50,
  target : 50,
  code: 50, 
  name: 150, 
  area: 60, pref: 90, 

  subInputFlg: 50, 
  rowHeader : 50, 
  rowHeaderName : 200, 
  rowHeaderMise: 70, 

  newCenterCd: 200,
  forecastEndYmd: 100,
  prevYearExcludedFlg: 100,
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  target: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  code: {type: 'text', readOnly: true,}, 
  name: {type: 'text', readOnly: true,}, 
  area: {type: 'text', readOnly: true,}, 
  pref: {type: 'text', readOnly: true,}, 

  subInputFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  rowHeader : {type: 'text', readOnly: true,}, 
  rowHeaderName : {type: 'text', readOnly: true,}, 
  rowHeaderMise: {type: 'text', readOnly: true,}, 

  newCenterCd: { type: 'text', readOnly: false, className: 'htLeft', source: [], },
  forecastEndYmd: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, format: 'YY/MM/DD', formatStrict: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort()} }, readOnly: false, className: 'htCenter' },
  prevYearExcludedFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
};

//列定義情報（動的パラメータ）
interface SettingTokuisakiColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
}

export class SettingTokuisakiColRowModel implements SettingTokuisakiColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //発注不可時非表示列
  colKeysUnOrderableHidden: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;

  constructor(param: SettingTokuisakiColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.colKeysUnOrderableHidden = param.colKeysUnOrderableHidden;
    this.colKeysUnOrderableHidden = param.colKeysUnOrderableHidden;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
}

//SettingTokuisaki用の列モデル
export const settingTokuisakiColRowModelDefault:SettingTokuisakiColRowModel = new SettingTokuisakiColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
  colKeysUnOrderableHidden: colKeysUnOrderableHidden, 
});
