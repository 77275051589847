import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import * as arrayUtil from "@/util/arrayUtil";
import { SettingTokuisakiColRowModel, settingTokuisakiColRowModelDefault } from "@/components/settingtokuisaki/SettingTokuisakiTableModel";
import { centerTokuisakiAttributes, listSortOrders } from "@/store/settingtokuisaki/settingTokuisakiCommon";

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  // "target", 
  "no", 
  "code", "name", 

  "subInputFlg",
  "rowHeader",
  // "rowHeaderName",
  // "rowHeaderMise",
  //入力項目
  'newCenterCd',
  'forecastEndYmd',
  'prevYearExcludedFlg',
];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  //以下に貼り付け
  "area", 
  "pref",
];

export const optionsNameList = [
  'optionBumons',
  'optionTokuisakis',
  'optionTokuisakiCode',
  'optionAreas',
  'optionCenters',
  'optionCenterCode',
];
export type SettingTokuisakiOptions = {
  optionBumons?: CodeName[],    //部門
  optionTokuisakis?: CodeName[],    //得意先
  optionTokuisakiCode?: string,     //得意先CD
  optionAreas?: CodeName[],     //地域
  optionCenters?: CodeName[],   //倉庫
  optionCenterCode?: string,    //倉庫CD
};

//Page State
export type SettingTokuisakiSaveState = {
  //検索条件
  refreshOptions_ver: number,
  optionBumons:CodeName[],    //部門
  optionTokuisakis:CodeName[],    //得意先
  optionTokuisakiCode:string,            //得意先CD
  optionAreas:CodeName[],     //地域
  optionCenters:CodeName[],   //倉庫
  optionCenterCode:string,            //倉庫CD

  favoriteOptions: { title: CodeName, params: SettingTokuisakiOptions }[] | null,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  
  colWidthsModified: {},
};

export const initialSettingTokuisakiState: SettingTokuisakiSaveState = {
  refreshOptions_ver: 0,
  optionBumons:[],    //部門
  optionTokuisakis:[],    //得意先
  optionTokuisakiCode:'',            //得意先CD
  optionAreas:[],     //地域
  optionCenters:[],   //倉庫
  optionCenterCode:'',            //倉庫CD

  favoriteOptions: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'center'),   //並び順
  listSortOrderDesc: false,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,

  colWidthsModified: {},

};

//Page Slice
export type SettingTokuisakiSaveReducer = {
  initOnDidMount: (state:SettingTokuisakiSaveState) => void,
  resetOnWillUnmount: (state:SettingTokuisakiSaveState) => void,
  clearOption: (state:SettingTokuisakiSaveState, action: PayloadAction<string>) => void,
  refreshOptions: (state: SettingTokuisakiSaveState) => void,
  setOptionBumons: (state:SettingTokuisakiSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionTokuisakis: (state:SettingTokuisakiSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionTokuisakiCode: (state:SettingTokuisakiSaveState, action: PayloadAction<string>) => void,
  setOptionAreas: (state:SettingTokuisakiSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:SettingTokuisakiSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenterCode: (state:SettingTokuisakiSaveState, action: PayloadAction<string>) => void,
  setFavoriteOptions: (state:SettingTokuisakiSaveState, action: PayloadAction<{ title: CodeName, params: SettingTokuisakiOptions }[]>) => void,
  setListSortOrder: (state:SettingTokuisakiSaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:SettingTokuisakiSaveState, action: PayloadAction<boolean>) => void,
  columnWidthChange: (state:SettingTokuisakiSaveState, action: PayloadAction<{colKey:string, width:number}>) => void,
  hideColumnKeysChange: (state:SettingTokuisakiSaveState, action: PayloadAction<string[]>) => void,
}

const createReducerContent = (name:string, colRowModel:SettingTokuisakiColRowModel, initialState: SettingTokuisakiSaveState):SettingTokuisakiSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:SettingTokuisakiSaveState) {
    if(state.optionBumons === undefined) {
      state.optionBumons = initialSettingTokuisakiState.optionBumons;
    }
    
    if(state.optionTokuisakis === undefined) {
      state.optionTokuisakis = initialSettingTokuisakiState.optionTokuisakis;
    }
    if(state.optionTokuisakiCode === undefined) {
      state.optionTokuisakiCode = initialSettingTokuisakiState.optionTokuisakiCode;
    }
    if(state.optionAreas === undefined) {
      state.optionAreas = initialSettingTokuisakiState.optionAreas;
    }
    if(state.optionCenters === undefined) {
      state.optionCenters = initialSettingTokuisakiState.optionCenters;
    }
    if(state.optionCenterCode === undefined) {
      state.optionCenterCode = initialSettingTokuisakiState.optionCenterCode;
    }
    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialSettingTokuisakiState.favoriteOptions;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    else if(state.listSortOrder.code) { //属性値を再選択する
      const code = state.listSortOrder.code;
      state.listSortOrder = listSortOrders.find(listSortOrder => listSortOrder.code == code);   //並び順
    }

    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }

    //初期表示列0配列は、常に全項目表示とする
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = colRowModel.colKeys;
    }
    else if(!state.visibleColumnsKey) {
      state.visibleColumnsKey = initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

    if(state.colWidthsModified === undefined) {
      state.colWidthsModified = initialState.colWidthsModified;
    }

  },
  //componentWillUnmount
  resetOnWillUnmount(state:SettingTokuisakiSaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しない
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state: SettingTokuisakiSaveState, action: PayloadAction<string>) {
    const key = action.payload;
    if (!key) {
      // state.optionBumons = initialSettingTokuisakiState.optionBumons;
    }
    if (!key || key === 'item') {
      state.optionTokuisakis = initialSettingTokuisakiState.optionTokuisakis;
      state.optionTokuisakiCode = initialSettingTokuisakiState.optionTokuisakiCode;
    }
    if (!key || key === 'center') {
      state.optionAreas = initialSettingTokuisakiState.optionAreas;
      state.optionCenters = initialSettingTokuisakiState.optionCenters;
      state.optionCenterCode = initialSettingTokuisakiState.optionCenterCode;
    }
  },
  refreshOptions (state: SettingTokuisakiSaveState) {
    state.refreshOptions_ver = state.refreshOptions_ver + 1;
  },
  setOptionBumons (state:SettingTokuisakiSaveState, action: PayloadAction<CodeName[]>) {
    state.optionBumons = action.payload;
  },
  setOptionTokuisakis (state:SettingTokuisakiSaveState, action: PayloadAction<CodeName[]>) {
    state.optionTokuisakis = action.payload;
  },
  setOptionTokuisakiCode (state:SettingTokuisakiSaveState, action: PayloadAction<string>) {
    state.optionTokuisakiCode = action.payload;
  },
  setOptionAreas (state:SettingTokuisakiSaveState, action: PayloadAction<CodeName[]>) {
    state.optionAreas = action.payload;
  },
  setOptionCenters (state:SettingTokuisakiSaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionCenterCode (state:SettingTokuisakiSaveState, action: PayloadAction<string>) {
    state.optionCenterCode = action.payload;
  },
  setFavoriteOptions(state:SettingTokuisakiSaveState, action: PayloadAction<{ title: CodeName, params: SettingTokuisakiOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setListSortOrder (state:SettingTokuisakiSaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:SettingTokuisakiSaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },

  columnWidthChange(state:SettingTokuisakiSaveState, action: PayloadAction<{colKey:string, width:number}>){
    console.log('store.columnWidthChange');
    const colKey = action.payload.colKey;
    const width = action.payload.width;
    if(colKey && width) {
      state.colWidthsModified[colKey] = width;
    }
  },
  hideColumnKeysChange(state:SettingTokuisakiSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
  
}};

const createSliceContent = (name:string, colRowModel:SettingTokuisakiColRowModel, initialState: SettingTokuisakiSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//settingTokuisakiSaveSlice
export const settingTokuisakiSaveSlice = createSliceContent("settingTokuisakiSave", settingTokuisakiColRowModelDefault, initialSettingTokuisakiState);
