import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";

import { CenterTokuisaki, InfoData, ErrorInfoData } from '@/store/settingtokuisaki/settingTokuisakiCommon'
import * as settingTokuisakiRetrieve from "@/assets/apitype/settingTokuisakiRetrieve";
import { SettingTokuisakiColRowModel, colDataType } from "@/components/settingtokuisaki/SettingTokuisakiTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";
import moment from "moment";



//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

//編集中判定
export const isEditedRowData = (data: InfoData): boolean => {
  if (!data) {
    return false;
  }

  const changed = 
    (
      data.oldSubInputFlg != data.subInputFlg ||
      data.oldNewCenterCd != data.newCenterCd ||
      data.oldForecastEndYmd != data.forecastEndYmd ||
      data.oldPrevYearExcludedFlg != data.prevYearExcludedFlg);

  return (
    changed
  );
}

export type RowDataGroup = {
  centerTokuisaki: CenterTokuisaki,
  target?: boolean,
  hasChildTarget?: boolean,
}

export type RowInfo = {
  TP: "tokuisaki" | "center" | "info" | "bulk",
  no?: string,
  subno?: string,
  dataGroup:RowDataGroup,

  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ
  row:number,  //行番号

  infoData? :InfoData,
  infoDataIndex?:number,
}
export interface EditCellInfo {
  row:number, 
  col:number, 
  value:string|number|object|null, 
  relatedValues?: {key:string, value:string|number|object|null}[],
}

//Page State
export type SettingTokuisakiTmpState = {
  // bumonList: CodeName[],  //部門
  tokuisakiList: CodeName[],  //得意先
  areaList: CodeName[], //地域
  centerList: CodeName[], //倉庫
  allCenterList: CodeName[], //全倉庫

  progress: Record<string, unknown>,
  retrievedParam: settingTokuisakiRetrieve.RequestParam,  //検索条件(検索済み)

  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  fixedRowsTop :number,
  infoDatas: InfoData[],
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[]
  tableVer_updateData: number,
  tableVer_updateData_rows: number[],
  tableVer_updateSettting: number,

  errorMessage: string | null,
  infoMessage: string | null,

  editing: boolean,
  functionKeys: string[],
  // openDialog: boolean,
};

export const initialState: SettingTokuisakiTmpState = {
  // bumonList: [],
  tokuisakiList: [],
  areaList: [],
  centerList: [],
  allCenterList: [],

  progress: {},
  retrievedParam: null,

  dataGroups: [],
  rowInfos: [],
  fixedRowsTop :0,
  infoDatas: [],
  rows: [],
  mergeCells: null,
  tableVer_updateData: 0,
  tableVer_updateData_rows: [],
  tableVer_updateSettting: 0,

  errorMessage: null,
  infoMessage: null,

  editing: false,
  functionKeys: [],
  // openDialog: boolean,
};

//Page Slice
export type SettingTokuisakiTmpReducer = {
  putProgress: (state:SettingTokuisakiTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:SettingTokuisakiTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:SettingTokuisakiTmpState, action: PayloadAction<string>) => void,
  setInfoMessage: (state:SettingTokuisakiTmpState, action: PayloadAction<string>) => void,
  setEditingStart: (state:SettingTokuisakiTmpState, action: PayloadAction<string>) => void,
  setEditingEnd: (state:SettingTokuisakiTmpState, action: PayloadAction<string>) => void,
  // setBumonList: (state:SettingTokuisakiTmpState, action: PayloadAction<CodeName[]>) => void,
  setTokuisakiList: (state:SettingTokuisakiTmpState, action: PayloadAction<CodeName[]>) => void,
  setAreaList: (state:SettingTokuisakiTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:SettingTokuisakiTmpState, action: PayloadAction<CodeName[]>) => void,
  setAllCenterList: (state:SettingTokuisakiTmpState, action: PayloadAction<CodeName[]>) => void,
  setRetrievedParam: (state:SettingTokuisakiTmpState, action: PayloadAction<settingTokuisakiRetrieve.RequestParam>) => void,

  searched: (state:SettingTokuisakiTmpState, action: PayloadAction<{param: settingTokuisakiRetrieve.RequestParam, centerTokuisakis: CenterTokuisaki[], infos: InfoData[], colRowModel:SettingTokuisakiColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean}>) => void,
  refreshTable: (state:SettingTokuisakiTmpState, action: PayloadAction<{colRowModel:SettingTokuisakiColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean}>) => void,
  editRowDatas: (state:SettingTokuisakiTmpState, action: PayloadAction<{colRowModel:SettingTokuisakiColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, editDatas:EditCellInfo[]}>) => void,
  setFunctionKey: (state:SettingTokuisakiTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = ():SettingTokuisakiTmpReducer => {return {
    putProgress(state:SettingTokuisakiTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:SettingTokuisakiTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:SettingTokuisakiTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setInfoMessage(state:SettingTokuisakiTmpState, action: PayloadAction<string>) {
      state.infoMessage = action.payload;
    },
    setEditingStart(state:SettingTokuisakiTmpState) {
      console.log('store.setEditingStart');
      state.editing = true;
    },
    setEditingEnd(state:SettingTokuisakiTmpState) {
      console.log('store.setEditingEnd');

      state.editing = false;

      state.progress= {};
      state.retrievedParam= null;
      
      state.dataGroups= [];
      state.rowInfos= [];
      state.fixedRowsTop =0;
      state.infoDatas= [];
      state.rows= [];
      state.mergeCells= null;

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    
      
    },
    // setBumonList(state:SettingTokuisakiTmpState, action: PayloadAction<CodeName[]>) {
    //   state.bumonList = action.payload;
    // },
    setTokuisakiList(state:SettingTokuisakiTmpState, action: PayloadAction<CodeName[]>) {
      state.tokuisakiList = action.payload;
    },
    setAreaList(state:SettingTokuisakiTmpState, action: PayloadAction<CodeName[]>) {
      state.areaList = action.payload;
    },
    setCenterList(state:SettingTokuisakiTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setAllCenterList(state:SettingTokuisakiTmpState, action: PayloadAction<CodeName[]>) {
      state.allCenterList = action.payload;
    },
    setRetrievedParam(state:SettingTokuisakiTmpState, action: PayloadAction<settingTokuisakiRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:SettingTokuisakiTmpState, action: PayloadAction<{param: settingTokuisakiRetrieve.RequestParam, centerTokuisakis: CenterTokuisaki[], infos: InfoData[], colRowModel:SettingTokuisakiColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean}>) {
      const setOldData = (data: InfoData) => {
        data.oldSubInputFlg = data.subInputFlg ? data.subInputFlg : false;
        data.oldNewCenterCd = data.newCenterCd ? data.newCenterCd : null;
        data.oldForecastEndYmd = data.forecastEndYmd ? data.forecastEndYmd : null;
        data.oldPrevYearExcludedFlg = data.prevYearExcludedFlg ? data.prevYearExcludedFlg : false;
      }
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;

      let centerTokuisakis = parseDataCenterTokuisaki(action.payload.centerTokuisakis);
      let infos = parseDataInfoData(action.payload.infos);
      //編集前のデータをセット
      infos.forEach(data => setOldData(data));
      infos = checkDatas(infos); //データチェック

      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      
      let dataGroups = convertDataGroups(centerTokuisakis);
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(dataGroups, listSortOrder, listSortOrderDesc, infos);
      //新規も編集前のデータをセットをセット
      newinfos.forEach(data => setOldData(data));

      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = newinfos;
      state.rows = convertRows(rowInfos, colRowModel, listSortOrder, listSortOrderDesc);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    refreshTable(state:SettingTokuisakiTmpState, action: PayloadAction<{colRowModel:SettingTokuisakiColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean}>){
      console.log('refreshTable');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const colRowModel = action.payload.colRowModel;
      let dataGroups = state.dataGroups;
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(dataGroups, listSortOrder, listSortOrderDesc, state.infoDatas);
      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.infoDatas = newinfos;
      state.rows = convertRows(rowInfos, colRowModel, listSortOrder, listSortOrderDesc);
      state.mergeCells = createMergeCells(rowInfos, colRowModel);

      state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },

    editRowDatas(state:SettingTokuisakiTmpState, action: PayloadAction<{colRowModel:SettingTokuisakiColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean, editDatas:EditCellInfo[]}>) {
      console.log('store.editRowDatas');
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const colRowModel = action.payload.colRowModel;
      const editDatas = action.payload.editDatas;

      // const newInfoDatas = [...state.infoDatas];
      const newInfoDatas = state.infoDatas;
      const newRows = state.rows;

      let targetChange = false;
      let subInputFlgChanged = false; //店舗別列が変更された

      let editedRows:Set<number> = new Set<number>();
      editDatas.forEach((editData)=>{
        const rowInfo:RowInfo = state.rowInfos[editData.row];
        if(!rowInfo) {
          return;
        }
        const dataGroup = rowInfo.dataGroup;
        const infoData = rowInfo.infoData;
        const infoDataIndex = rowInfo.infoDataIndex;
        if(!dataGroup || !infoData) {
          return;
        }

        const colKey:string = colRowModel.keyFromCol(editData.col);

        subInputFlgChanged = subInputFlgChanged || colKey == "subInputFlg";
        
        let dataType = colDataType[colKey];

        const value = 
          editorUtil.parseValue(editData.value, dataType.type,
            dataType.type == 'numeric' ? dataType.numericFormat.pattern :
            dataType.type == 'date' ? dataType.dateFormat :
            dataType.type == 'time' ? dataType.timeFormat :
            null);

        if(colKey == 'target') {
          const rowTop = rowInfo.row - rowInfo.rowIndex;
          const rowInfoTop = state.rowInfos[rowTop];
          const dataGroupTop = rowInfoTop.dataGroup;
          dataGroupTop.target = value == true;
          editedRows.add(rowTop);
          rowInfoTop.dataGroup = dataGroupTop;
          state.rowInfos[rowTop] = rowInfoTop;

          targetChange = true;
        }
        else {
          newInfoDatas[infoDataIndex][colKey] = value;
          editedRows.add(editData.row);
          newInfoDatas[infoDataIndex] = checkData(newInfoDatas[infoDataIndex]);
          state.rowInfos[editData.row].infoData = newInfoDatas[infoDataIndex];
        }  
      });

      //選択有無を記録する
      if(targetChange) {
        const rowInfoBulk = state.rowInfos[0];
        const dataGroupBulk = rowInfoBulk.dataGroup;
        dataGroupBulk.hasChildTarget = !(!(state.rowInfos.find(rowInfo => rowInfo.dataGroup?.target)));
        rowInfoBulk.dataGroup = dataGroupBulk;
      }

      if(editedRows.size > 0) {
        const tableVer_updateData_rows = [];
        editedRows.forEach(row => {
          tableVer_updateData_rows.push(row);
          newRows[row] = convertRow(state.rowInfos[row], colRowModel, listSortOrder, listSortOrderDesc);
        });
        
        //店舗別チェックが入っていたらテーブル再構築 
        if(subInputFlgChanged) {
          const [rowInfos, fixedRowsTop, newinfos] = convertRowInfos(state.dataGroups, listSortOrder, listSortOrderDesc, state.infoDatas);
          //store更新
          state.rowInfos = rowInfos;
          state.fixedRowsTop = fixedRowsTop;
          state.infoDatas = newinfos;
          state.rows = convertRows(rowInfos, colRowModel, listSortOrder, listSortOrderDesc);
          state.mergeCells = createMergeCells(rowInfos, colRowModel);
    
          state.tableVer_updateSettting = state.tableVer_updateSettting+1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
        } else {
          state.tableVer_updateData_rows = tableVer_updateData_rows;
          state.tableVer_updateData = state.tableVer_updateData+1;  //カウントアップしテーブルを再描写対象とする (updateData)
        }
      }
    },
    setFunctionKey(state:SettingTokuisakiTmpState, action: PayloadAction<string>) {
      state.functionKeys = action.payload ? [action.payload] : [];
    },
}};

const parseDataCenterTokuisaki = (datas:CenterTokuisaki[]): CenterTokuisaki[] => {
  datas = [...datas];
  datas.forEach((data) => {
  });
  return datas;
}
const parseDataInfoData = (datas:InfoData[]): InfoData[] => {
  // datas = [...datas];
  // datas.forEach((data) => {
  //   if(typeof data.leadTimeDays === 'string') data.leadTimeDays = parseInt(data.leadTimeDays);
  //   if(typeof data.minQtyCs === 'string') data.minQtyCs = parseInt(data.minQtyCs);
  //   if(typeof data.maxQtyCs === 'string') data.maxQtyCs = parseInt(data.maxQtyCs);
  //   if(typeof data.autoOrderDays === 'string') data.autoOrderDays = parseInt(data.autoOrderDays);
  //   if(typeof data.autoOrderExecDays === 'string') data.autoOrderExecDays = parseInt(data.autoOrderExecDays);
  // });
  return datas;
}

//チェック
const checkDatas = (datas:InfoData[]): InfoData[] => {
  console.log('store.checkDatas');
  return datas.map(data => checkData(data));
}
const checkData = (data: InfoData): InfoData => {
  //編集状態をセットする
  // Object.keys(data).forEach(key => {
    data.edited = isEditedRowData(data);
  // });


  const errorInfo: ErrorInfoData = {};
  if (!data.errorInfo) {
    data.errorInfo = {};
  }

  if (data.newCenterCd) {
    const regex = new RegExp(/^[0-9]{4,4}$/);
    if (!regex.test(data.newCenterCd.substring(0,4))) {
      errorInfo.newCenterCd = '4桁数字で入力してください';
    }
  }

  if(data.forecastEndYmd && !moment('20' + data.forecastEndYmd).isValid()) {
    errorInfo.forecastEndYmd = '日付が不正';
  }

  data.errorInfo = errorInfo;

  return data;
}

//RowDataGroupに変換
const convertDataGroups = (centerTokuisakis: CenterTokuisaki[]): RowDataGroup[] => {
  //得意先、センター順でソート
  centerTokuisakis = centerTokuisakis.sort((a,b) => {
    const objA = a;
    const objB = b;
    let comp = 0;
    comp = compareUtil.compareString(a.tokuisakiCD, b.tokuisakiCD, true)
    if(comp == 0) {
      comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
    }
    return comp;
  });

  return centerTokuisakis.map(centerTokuisaki => {
    return {
      centerTokuisaki: centerTokuisaki,
    };
  });
}
//並び順変更
const sortDataGroups = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean): RowDataGroup[] => {
  if(!dataGroups) {
    return dataGroups;
  }
  let asc = !listSortOrderDesc;
  let getSortKey1 = (o:RowDataGroup):string|number => 
    !o || !o.centerTokuisaki ? null : 
    !listSortOrder ? o.centerTokuisaki.tokuisakiCD :
    listSortOrder.code == 'tokuisakiCD' ? o.centerTokuisaki.tokuisakiCD :
    listSortOrder.code == 'tokuisakiNM' ? o.centerTokuisaki.tokuisakiNM :
    listSortOrder.code == 'tokuisakiCD_area' ? o.centerTokuisaki.tokuisakiCD :
    listSortOrder.code == 'tokuisakiNM_area' ? o.centerTokuisaki.tokuisakiNM :
    listSortOrder.code == 'tokuisakiCD_pref' ? o.centerTokuisaki.tokuisakiCD :
    listSortOrder.code == 'tokuisakiNM_pref' ? o.centerTokuisaki.tokuisakiNM :
    listSortOrder.code == 'center' ? o.centerTokuisaki.centerCD :
    listSortOrder.code == 'area' ? o.centerTokuisaki.areaCD :
    listSortOrder.code == 'pref' ? o.centerTokuisaki.prefCD :
    o.centerTokuisaki.tokuisakiCD
  ;
  let getSortKey2 = (o:RowDataGroup):string|number => 
    !o || !o.centerTokuisaki ? null : 
    !listSortOrder ? o.centerTokuisaki.tokuisakiCD :
    listSortOrder.group_code == 'tokuisaki' ? o.centerTokuisaki.tokuisakiCD :
    listSortOrder.group_code == 'center' ? o.centerTokuisaki.centerCD :
    o.centerTokuisaki.tokuisakiCD
  ;
  let getSortKey3 = (o:RowDataGroup):string|number => 
    !o || !o.centerTokuisaki ? null : 
    !listSortOrder ? o.centerTokuisaki.tokuisakiCD :
    listSortOrder.code == 'tokuisakiCD' ? o.centerTokuisaki.centerCD :
    listSortOrder.code == 'tokuisakiNM' ? o.centerTokuisaki.centerCD :
    listSortOrder.code == 'tokuisakiCD_area' ? o.centerTokuisaki.areaCD :
    listSortOrder.code == 'tokuisakiNM_area' ? o.centerTokuisaki.areaCD :
    listSortOrder.code == 'tokuisakiCD_pref' ? o.centerTokuisaki.prefCD :
    listSortOrder.code == 'tokuisakiNM_pref' ? o.centerTokuisaki.prefCD :
    listSortOrder.code == 'center' ? o.centerTokuisaki.tokuisakiCD :
    listSortOrder.code == 'area' ? o.centerTokuisaki.tokuisakiCD :
    listSortOrder.code == 'pref' ? o.centerTokuisaki.tokuisakiCD :
    o.centerTokuisaki.centerCD
  ;
  dataGroups.sort((a, b) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny(va, vb, asc);
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    return comp;
  });
  return dataGroups;
}
  //行情報に変換
const convertRowInfos = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean, infoDatas: InfoData[]): [RowInfo[], number, InfoData[]] => {
  let newInfoDatas: InfoData[] = [...infoDatas];
  //map作成
  const infoDataGroupMap = {};
  const infoDatasMap = {};
  const infoDataIndexMap = {};
  newInfoDatas.forEach((infoData, index) => {
    const key_dataGroup = `${infoData.tokuisakiCD} ${infoData.centerCD}`;
    const key_infoData = `${infoData.tokuisakiCD} ${infoData.centerCD} ${infoData.tokuisakiSubCD}`;
    //本部のレコードのマップ
    if(infoData.tokuisakiSubCD == '999') {
      infoDataGroupMap[key_dataGroup] = infoData;
    }
    else {
      //個別指定でなければreturn
      const infoDataGroup = infoDataGroupMap[key_dataGroup];
      if(!infoDataGroup?.subInputFlg) {
        return;
      }
    }

    let infoDatas = infoDatasMap[key_dataGroup];
    if(!infoDatas) {
      infoDatas = [];
      infoDatasMap[key_dataGroup] = infoDatas;
    }
    infoDatas.push(infoData);

    infoDataIndexMap[key_infoData] = index;
  });

  const rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;


  //明細行
  fixedRowsTop = rowInfos.length;


  let beforeDataGroup:RowDataGroup;
  const grouping = (!listSortOrder || listSortOrder.group_code == 'tokuisaki') ? 'tokuisaki' : 'center';
  let no = 0;
  let subno = 0;
  dataGroups.forEach(dataGroup => {
    // グルーピング
    if(grouping == 'tokuisaki' && (!beforeDataGroup || beforeDataGroup.centerTokuisaki.tokuisakiCD != dataGroup.centerTokuisaki.tokuisakiCD)) {
      no++;
      subno = 0;
      rowInfos.push({
        TP: "tokuisaki",
        no: `${no}`,
        dataGroup: dataGroup,
        row: rowInfos.length,
      });
    }
    else if(grouping == 'center' && (!beforeDataGroup || beforeDataGroup.centerTokuisaki.centerCD != dataGroup.centerTokuisaki.centerCD)) {
      no++;
      subno = 0;
      rowInfos.push({
        TP: "center",
        no: `${no}`,
        dataGroup: dataGroup,
        row: rowInfos.length,
      });
    }

    //明細行
    subno++;
    const key_dataGroup = `${dataGroup.centerTokuisaki.tokuisakiCD} ${dataGroup.centerTokuisaki.centerCD}`;
    let infoDatas = infoDatasMap[key_dataGroup];
    infoDatas.forEach((infoData, index) => {
      const key_infoData = `${infoData.tokuisakiCD} ${infoData.centerCD} ${infoData.tokuisakiSubCD}`;
        let infoDataIndex:number = infoDataIndexMap[key_infoData];
      rowInfos.push({
        ...{
          TP: "info",
          no: `${no}`,
          subno: `${subno}`,
          dataGroup: dataGroup,
          infoData: infoData,
          infoDataIndex: infoDataIndex,
          row: rowInfos.length,
          rowIndex: index,
          rowIndexLast: index == infoDatas.length - 1,
        }, 
      });
    })

    beforeDataGroup = dataGroup;
  });
  return [rowInfos, fixedRowsTop, newInfoDatas];
}
//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:SettingTokuisakiColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel, listSortOrder, listSortOrderDesc));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:SettingTokuisakiColRowModel, listSortOrder:CodeName, listSortOrderDesc:boolean): any[] => {
  //set No.
  const dataGroup:RowDataGroup = rowInfo.dataGroup;
  return colRowModel.colKeys.map(colKey => valueFromKey(colKey, rowInfo, dataGroup, listSortOrder));
}
export const valueFromKey = (colKey: string, rowInfo: RowInfo, dataGroup: RowDataGroup, listSortOrder: CodeName): any => {
  //一括
  if (rowInfo.TP == "bulk") {

    //明細行
    switch (colKey) {
      case "target":
        return rowInfo.dataGroup?.target;
      case "no":
        return "";
      case "code":
      case "name":
      case "area":
      case "pref":
      case "rowHeader":
      case "rowHeaderName":
      case "rowHeaderMise":
        return null;
  
      case "subInputFlg":
        return null;
      case "newCenterCd":
        return rowInfo.infoData.newCenterCd;
      case "forecastEndYmd":
        return rowInfo.infoData.forecastEndYmd;
      case "prevYearExcludedFlg":
        return rowInfo.infoData.prevYearExcludedFlg;
          
      default: {
        return dataGroup[colKey];
      }
    }
  }


  //グループ行
  if (rowInfo.TP == "center" || rowInfo.TP == "tokuisaki") {
    switch (colKey) {
      case "no":
        return rowInfo.no;
      case "target":
        return "";
      case "code":
        return rowInfo.TP == "center" ? dataGroup.centerTokuisaki.centerCD2 : rowInfo.TP == "tokuisaki" ? dataGroup.centerTokuisaki.tokuisakiCD : null;
      case "name":
        return rowInfo.TP == "center" ? dataGroup.centerTokuisaki.centerNM : rowInfo.TP == "tokuisaki" ? dataGroup.centerTokuisaki.tokuisakiNM : null;
      case "area":
        return rowInfo.TP == "center" ? dataGroup.centerTokuisaki.areaNM : null;
      case "pref":
        return rowInfo.TP == "center" ? dataGroup.centerTokuisaki.prefNM : null;
      case "rowHeader":
        return null;
      default: {
        return null;
      }
    }
  }
  if (rowInfo.TP == "info") {
    //明細行
    switch (colKey) {
      case "target":
        return rowInfo.dataGroup?.target;
      case "no":
        return `${rowInfo.no}-${rowInfo.subno}`;
      case "code":
        return rowInfo.infoData.tokuisakiSubCD != '999' ? '' : 
          listSortOrder.group_code == "center" ? dataGroup.centerTokuisaki.tokuisakiCD : listSortOrder.group_code == "tokuisaki" ? dataGroup.centerTokuisaki.centerCD2 : null;
      case "name":
        return rowInfo.infoData.tokuisakiSubCD != '999' ? '' : 
          listSortOrder.group_code == "center" ? dataGroup.centerTokuisaki.tokuisakiNM : listSortOrder.group_code == "tokuisaki" ? dataGroup.centerTokuisaki.centerNM : null;
      case "area":
        return rowInfo.infoData.tokuisakiSubCD != '999' ? '' : 
          listSortOrder.group_code == "tokuisaki" ? dataGroup.centerTokuisaki.areaNM : null;
      case "pref":
        return rowInfo.infoData.tokuisakiSubCD != '999' ? '' : 
          listSortOrder.group_code == "tokuisaki" ? dataGroup.centerTokuisaki.prefNM : null;
      case "subInputFlg":
        return rowInfo.infoData.subInputFlg;
      case "rowHeader":
        return rowInfo.infoData.tokuisakiSubCD == '999' ? '(全て)' : 
          rowInfo.infoData.tokuisakiSubCD;
      case "rowHeaderName":
        return rowInfo.infoData.tokuisakiSubCD == '999' ? '' : 
          rowInfo.infoData.tokuisakiSubNM;
      case "rowHeaderMise":
        return rowInfo.infoData.tokuisakiSubCD == '999' ? '' : 
          rowInfo.infoData.tokuisakiMise;
        
      case "newCenterCd":
        return rowInfo.infoData.newCenterCd;
      case "forecastEndYmd":
        return rowInfo.infoData.forecastEndYmd;
      case "prevYearExcludedFlg":
        return rowInfo.infoData.prevYearExcludedFlg;
          
      default: {
        return dataGroup[colKey];
      }
    }
  }
  return null;
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:SettingTokuisakiColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  return mergeCells;
}


export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//settingTokuisakiTmp
export const settingTokuisakiTmpSlice = createSliceContent("settingTokuisakiTmp");
