import { CodeName } from "@/assets/commontype/CodeName";

//倉庫・得意先
export type CenterTokuisaki = {
  tokuisakiCD?: string | null, //得意先
  tokuisakiNM?: string | null, //得意先

  bumonCD?: string, //--部門
  centerCD?: string | null, //倉庫(4桁コード)
  centerCD2?: string | null, //倉庫(2桁コード)
  centerNM?: string | null, //倉庫
  areaCD?: string | null, //地域
  areaNM?: string | null, //地域
  prefCD?: string | null, //都道府県
  prefNM?: string | null, //都道府県

  centerSortInput?: number | null, //倉庫並び順
  centerSortOutput?: number | null, //倉庫並び順
}

export interface ErrorInfoData {
  subInputFlg?: string | null,
  newCenterCd?: string | null,
  forecastEndYmd?: string | null,
  prevYearExcludedFlg?: string | null,
}
export interface InfoData {
  uuid?: string,  //データベースに登録済みのUUID。更新時にDB上の値とずれていれば登録エラーとする

  tokuisakiCD?: string | null, //得意先
  centerCD?: string | null, //倉庫

  tokuisakiSubCD?: string | null, //得意先支店
  tokuisakiSubNM?: string | null, //得意先支店
  tokuisakiMise?: string | null, //得意先店番号

  newCenterCd?: string,	//移管先センター
  forecastEndYmd?: string,	//予測計算終了日
  prevYearExcludedFlg?: boolean,	//前年実績から除外フラグ

  subInputFlg?: boolean,	//支店別指定フラグ

	//エラー情報
	errorInfo?: ErrorInfoData,

	edited?: boolean, //編集

	oldNewCenterCd?: string,	//移管先センター
	oldForecastEndYmd?: string,	//予測計算終了日
	oldPrevYearExcludedFlg?: boolean,	//前年実績から除外フラグ
	oldSubInputFlg?: boolean,	//支店別指定フラグ
}

export const centerTokuisakiAttributes:CodeName[][] = [
  [
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "area", name: "地域", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "pref", name: "都道府県", }),
    new CodeName({group_code:"tokuisaki", group_name:"得意先属性", code: "tokuisakiCD", name: "得意先CD", }),
    new CodeName({group_code:"tokuisaki", group_name:"得意先属性", code: "tokuisakiNM", name: "得意先名", }),
  ],
  [
    new CodeName({group_code:"center", group_name:"センター属性", code: "center", name: "倉庫", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "area", name: "地域", }),
    new CodeName({group_code:"center", group_name:"センター属性", code: "pref", name: "都道府県", }),
    new CodeName({group_code:"tokuisaki", group_name:"得意先属性", code: "tokuisakiCD", name: "得意先CD", }),
    new CodeName({group_code:"tokuisaki", group_name:"得意先属性", code: "tokuisakiNM", name: "得意先名", }),
  ]
];

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"tokuisaki", group_name:"得意先→センター", code: "tokuisakiCD", name: "得意先コード→倉庫順", }),
  new CodeName({group_code:"tokuisaki", group_name:"得意先→センター", code: "tokuisakiNM", name: "得意先名→倉庫順", }),
  new CodeName({group_code:"tokuisaki", group_name:"得意先→センター", code: "tokuisakiCD_area", name: "得意先コード→地域順", }),
  new CodeName({group_code:"tokuisaki", group_name:"得意先→センター", code: "tokuisakiNM_area", name: "得意先名→地域順", }),
  new CodeName({group_code:"tokuisaki", group_name:"得意先→センター", code: "tokuisakiCD_pref", name: "得意先コード→都道府県順", }),
  new CodeName({group_code:"tokuisaki", group_name:"得意先→センター", code: "tokuisakiNM_pref", name: "得意先名→都道府県順", }),

  new CodeName({group_code:"center", group_name:"センター→得意先", code: "center", name: "倉庫", }),
  new CodeName({group_code:"center", group_name:"センター→得意先", code: "area", name: "地域", }),
  new CodeName({group_code:"center", group_name:"センター→得意先", code: "pref", name: "都道府県", }),

];