import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";
import { OptionItemTag } from "@/assets/commontype/ItemTag";
import { OptionTerm } from "@/components/custom/TermOptionSelecter";

export const centersNameList = [
  'optionBumons',
  'optionAreas',
  'optionCenters',
  'optionCenterCode',
];
export type CenterOptions = {
  optionBumons?: CodeName[],         //部門
  optionAreas?: CodeName[],         //地域
  optionCenters?: CodeName[],       //倉庫
  optionCenterCode?: string,        //倉庫CD
};

export const makersNameList = [
  'optionMakers',
  'optionMakerCode',
];
export type MakerOptions = {
  optionMakers?: CodeName[],    //メーカー
  optionMakerCode?: string,            //メーカーCD
};

export const tokuisakisNameList = [
  'optionTokuisakis',
  'optionTokuisakiCode',
];
export type TokuisakiOptions = {
  optionTokuisakis?: CodeName[],    //得意先
  optionTokuisakiCode?: string,            //得意先CD
};

export const itemsNameList = [
  'optionMakers',
  'optionMakerCode',
  'optionItemCode',
  'optionItemKeyword',
  'optionItemTags',
  'optionTermShubai',
  'optionOrderUsers',
  'optionTags',
];
export const itemsNameList2 = [
  'optionMakers',
  'optionMakerCode',
  'optionItemCode',
  'optionItemKeyword',
];
export type ItemOptions = {
  optionMakers?: CodeName[],    //メーカー
  optionMakerCode?: string,            //メーカーCD
  optionItemCode?: string,            //商品CD
  optionItemTags?: OptionItemTag[],            //商品タグ
  optionTermShubai?: OptionTerm | null,  //終売日
  optionOrderUsers?: CodeName[],   //担当メーカー
  optionTags?: CodeName[],    //タグ
};

export type UserSaveState = {
  url: string,
  favoriteCenters: { title: CodeName, params: CenterOptions }[] | null,
  favoriteMakers: { title: CodeName, params: MakerOptions }[] | null,
  favoriteItems: { title: CodeName, params: ItemOptions }[] | null,
  favoriteTokuisakis: { title: CodeName, params: TokuisakiOptions }[] | null,
};

const initialState: UserSaveState = {
  url: null,
  favoriteCenters: [],
  favoriteMakers: [],
  favoriteItems: [],
  favoriteTokuisakis: [],
};

//Page Slice
export type UserSaveReducer = {
  initOnDidMount: (state: UserSaveState) => void,
  resetOnWillUnmount: (state: UserSaveState) => void,
  setUrl: (state, action: PayloadAction<string>) => void,
  setFavoriteCenters: (state: UserSaveState, action: PayloadAction<{ title: CodeName, params: CenterOptions }[]>) => void,
  setFavoriteMakers: (state: UserSaveState, action: PayloadAction<{ title: CodeName, params: MakerOptions }[]>) => void,
  setFavoriteTokuisakis: (state: UserSaveState, action: PayloadAction<{ title: CodeName, params: TokuisakiOptions }[]>) => void,
  setFavoriteItems: (state: UserSaveState, action: PayloadAction<{ title: CodeName, params: ItemOptions }[]>) => void,
}

const createReducerContent = (name: string, initialState: UserSaveState): UserSaveReducer => {
  return {
    //componentDidMount
    initOnDidMount(state: UserSaveState) {
      if(state.favoriteCenters === undefined) {
        state.favoriteCenters = initialState.favoriteCenters;
      }
      if(state.favoriteItems === undefined) {
        state.favoriteItems = initialState.favoriteItems;
      }
      if(state.favoriteMakers === undefined) {
        state.favoriteMakers = initialState.favoriteMakers;
      }
      if(state.favoriteTokuisakis === undefined) {
        state.favoriteTokuisakis = initialState.favoriteTokuisakis;
      }

      //favoriteCentersに部門を持たせることによる補完。全て31として処理する
      state.favoriteCenters = state.favoriteCenters.map(favoriteCenter => {
        if(!favoriteCenter.params.optionBumons?.length) {
          favoriteCenter.params = {
            ...favoriteCenter.params,
            ...{optionBumons: [new CodeName({code: "31", name:"31"})]}
          };
        }
        return favoriteCenter;
      });
    },
    //componentWillUnmount
    resetOnWillUnmount(state: UserSaveState) {
    },
    setUrl(state, action: PayloadAction<string>) {
      state.url = action.payload;
    },
    setFavoriteCenters(state: UserSaveState, action: PayloadAction<{ title: CodeName, params: CenterOptions }[]>) {
      state.favoriteCenters = action.payload;
    },
    setFavoriteMakers(state: UserSaveState, action: PayloadAction<{ title: CodeName, params: MakerOptions }[]>) {
      state.favoriteMakers = action.payload;
    },
    setFavoriteTokuisakis(state: UserSaveState, action: PayloadAction<{ title: CodeName, params: TokuisakiOptions }[]>) {
      state.favoriteTokuisakis = action.payload;
    },
    setFavoriteItems(state: UserSaveState, action: PayloadAction<{ title: CodeName, params: ItemOptions }[]>) {
      state.favoriteItems = action.payload;
    },
  }
};

const createSliceContent = (name: string, initialState: UserSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, initialState),
});

export const userSaveSlice = createSliceContent("userSave", initialState);